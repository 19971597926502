import React, { useState, useEffect } from "react"

//Assets
import logoLight from "assets/img/common/logo_main-light-alt.png"
import logoDark from "assets/img/common/logo_main-dark-alt.png"
import logoDwpLight from "assets/img/dwp/dwp-x-monstore_light.png"
import logoDwpDark from "assets/img/dwp/dwp-x-monstore_dark.png"

//Utils
import { parseCookies, setCookie } from "nookies"
import { useSelector, useDispatch } from "react-redux"
import useConnectWallet from "lib/web3/hooks/useConnectWallet"
import { truncate } from "lib/web3/utils/helpers"
import { useIsomorphicLayoutEffect } from "components/hooks/hooks"
import {
  F_NAVBAR_MOBILE_MENU,
  F_NAVBAR_MOBILE_SHOP_MENU,
  F_NAVBAR_MOBILE_ACCOUNT_MENU,
  F_NAVBAR_SEARCH,
  F_NAVBAR_SEARCH_MOBILE,
  F_NAVBAR_BAG_MENU,
} from "./utils/navbar-functions"
import { SET_IS_DRAWER, SET_TOAST } from "lib/redux/type"
import {
  my_account_link,
  my_address_link,
  my_order_link,
  login_link,
  search_link,
} from "components/links"
import Cookies from "js-cookie"
import router, { useRouter } from "next/router"

//Components
import { Link } from "components/anti"
import DesktopMenu from "./components/desktop-menu"
import BagMenu from "components/anti/navbar/components/bag-menu"
import SearchMenu from "./components/search-menu"
import MegaMenu from "./components/mega-menu"
import ModalDisconnectWallet from "components/global/modal-disconnect-wallet"

export const Navbar = ({ handleOpen, dark, isTransparent }) => {
  // Navbar expand config
  // Change the const below & config on variables/navbar.scss when setting the .navbar-expand-*
  // e.g. sm, md, lg, xl, xxl
  const navExpand = "lg"

  // Default theme
  // Options: "navbar-dark", "navbar-light"
  // const navTheme = "navbar-light"

  // Default position
  // Options: "fixed-top", "absolute-top", ""
  const navPosition = "fixed-top"

  // IF NO NAVBAR EXPAND USED (no responsive navbar), do the following:
  // 1. In variables/navbar.scss, set $navbar-top-height-mobile & $navbar-top-height-desktop to 0
  // 2. In variables/navbar.scss, set $navbar-main-height-mobile & $nabvar-main-height-desktop to the same height
  // 3. In variables/navbar.scss, set $navbar-expand-up to ""
  // 4. In navbar.js, edit const navbarExpand to ""

  //!Utils ****************************************************************************************** //
  const dispatch = useDispatch()
  const { guest_checkout } = useSelector((state) => state.general)
  const Router = useRouter()
  const [navTheme] = useState(
    isTransparent ? "transparent" : dark ? "navbar-dark" : "navbar-light"
  )
  const { account } = useConnectWallet()
  const isDwpPage =
    Router.asPath === "/dwp" || Router.asPath === "/dwp/golden-ticket"
  //!Utils ****************************************************************************************** //

  //!States ***************************************************************************************** //
  // SCROLL
  // On scroll state
  const [navScroll, setNavScroll] = useState(false)

  // MENU - MOBILE
  // Open / close menu state & handler
  const [mobileMenu, setMobileMenu] = useState({
    initial: false,
    clicked: null,
  })

  // Toggler disabled state & handler
  const [disabledMenu, setDisabledMenu] = useState(false)

  // MENU SHOP - MOBILE
  // Open / close menu state & handler
  const [mobileShopMenu, setMobileShopMenu] = useState({
    initial: false,
    clicked: null,
  })

  // MENU ACCOUNT - MOBILE
  // Open / close menu state & handler
  const [mobileAccountMenu, setMobileAccountMenu] = useState({
    initial: false,
    clicked: null,
  })

  // SEARCH
  // Open / close menu state & handler
  const [search, setSearch] = useState({
    initial: false,
    clicked: null,
  })

  // Toggler disabled state & handler
  const [disabledSearch, setDisabledSearch] = useState(false)

  // SEARCH MOBILE
  // Open / close menu state & handler
  const [searchMobile, setSearchMobile] = useState({
    initial: false,
    clicked: null,
  })

  const [accountDropdown, setAccountDropdown] = useState(false)

  // Toggler disabled state & handler
  const [disabledSearchMobile, setDisabledSearchMobile] = useState(false)

  //Shopping bag menu
  const { shoppingBagMenu } = useSelector((state) => state.general)

  //User data
  const { user_data, my_carts } = useSelector((state) => state.auth)

  const [showMegaMenu, setShowMegaMenu] = useState({
    show: false,
    data: null,
  })

  const [modalDisconnect, setModalDisconnect] = useState(false)

  //!States ***************************************************************************************** //

  //!Functions ************************************************************************************** //

  const handleLogout = () => {
    Cookies.remove(process.env.ACCESS_TOKEN)
    Cookies.remove(process.env.GUEST_TOKEN)
    window.location.href = login_link
  }

  const disableMenu = () => {
    setDisabledMenu(!disabledMenu)
    setTimeout(() => {
      setDisabledMenu(false)
    }, 1500) // set this with your mobile menu animation duration
  }

  const disableSearch = () => {
    setDisabledSearch(!disabledSearch)
    setTimeout(() => {
      setDisabledSearch(false)
    }, 1500) // set this with your mobile menu animation duration
  }

  const handleSearch = () => {
    if (router.pathname !== search_link) {
      disableSearch()
      setShowMegaMenu({ show: false, data: { ...showMegaMenu.data } })
      if (search.initial === false) {
        setSearch({ initial: null, clicked: true })
      } else if (search.clicked === true) {
        setSearch({ clicked: !search.clicked })
      } else if (search.clicked === false) {
        setSearch({ clicked: !search.clicked })
      }
    }
  }

  const disableSearchMobile = () => {
    setDisabledSearchMobile(!disabledSearchMobile)
    setTimeout(() => {
      setDisabledSearchMobile(false)
    }, 1500) // set this with your mobile menu animation duration
  }

  const handleSearchMobile = () => {
    disableSearchMobile()
    if (searchMobile.initial === false) {
      setSearchMobile({ initial: null, clicked: true })
    } else if (searchMobile.clicked === true) {
      setSearchMobile({ clicked: !searchMobile.clicked })
    } else if (searchMobile.clicked === false) {
      setSearchMobile({ clicked: !searchMobile.clicked })
    }
  }

  const handleShoppingBag = () => {
    if (!guest_checkout) {
      disableMenu()
      if (shoppingBagMenu.initial === false) {
        dispatch({
          type: SET_IS_DRAWER,
          payload: { initial: null, clicked: true },
        })
      } else if (shoppingBagMenu.clicked === true) {
        dispatch({ type: SET_IS_DRAWER, payload: { clicked: false } })
        // setShoppingBagMenu({ clicked: !shoppingBagMenu.clicked })
      } else if (shoppingBagMenu.clicked === false) {
        dispatch({ type: SET_IS_DRAWER, payload: { clicked: true } })
      }
    } else {
      Router.push(login_link)
    }
  }
  useEffect(() => {
    if (Router.query["checkout-validation-tsel"]) {
      handleShoppingBag()
    }
  }, [Router.query])
  //!Functions ************************************************************************************** //

  //!React Operatons ******************************************************************************** //
  useIsomorphicLayoutEffect(() => {
    if (window.pageYOffset > 20) {
      setNavScroll(true)
    }
    window.onscroll = function () {
      if (window.pageYOffset > 20) {
        setNavScroll(true)
      } else {
        setNavScroll(false)
      }
    }
  }, [])
  useIsomorphicLayoutEffect(
    () => F_NAVBAR_MOBILE_MENU(mobileMenu),
    [mobileMenu]
  )
  useIsomorphicLayoutEffect(
    () => F_NAVBAR_MOBILE_SHOP_MENU(mobileShopMenu),
    [mobileShopMenu]
  )
  useIsomorphicLayoutEffect(
    () => F_NAVBAR_MOBILE_ACCOUNT_MENU(mobileAccountMenu),
    [mobileAccountMenu]
  )
  useIsomorphicLayoutEffect(() => F_NAVBAR_SEARCH(search), [search])
  useIsomorphicLayoutEffect(() => F_NAVBAR_SEARCH_MOBILE(searchMobile))
  useIsomorphicLayoutEffect(
    () => F_NAVBAR_BAG_MENU(shoppingBagMenu),
    [shoppingBagMenu]
  )
  //!React Operatons ******************************************************************************** //

  //!Verify Email Toast ******************************************************************************** //
  const cookies = parseCookies()
  const showToastVerify = cookies?.show_toast_verify

  useEffect(() => {
    if (user_data && !user_data.email_verified_at && showToastVerify) {
      dispatch({
        type: SET_TOAST,
        payload: {
          show: true,
          message: `Verify your email address. We’ve sent the link to ${user_data.email}.`,
          status: "verify",
        },
      })
      setCookie(null, "show_toast_verify", "", {
        maxAge: 30 * 24 * 60 * 60,
        path: "/",
      })
    }
  }, [])
  //!Verify Email Toast ******************************************************************************** //
  return (
    <>
      {/* When giving .navbar-expand-*, don't forget to modify the variables on _navbar.scss */}
      <nav
        className={`
          navbar ${navTheme} navbar-expand-${navExpand} ${navPosition}
          ${navScroll ? "is-scrolled" : ""}
          ${mobileMenu.clicked ? "mobile-menu-open" : ""}
          ${shoppingBagMenu.clicked ? "shopping-bag-menu-open" : ""}
        `}
      >
        <div className="navbar-main">
          <div className="container">
            <div className="col-menu">
              <DesktopMenu
                handleSelectMenu={setShowMegaMenu}
                megamenu={showMegaMenu}
              />
            </div>
            <div className="col-brand">
              <Link className={`navbar-brand ${isDwpPage ? "dwp" : ""}`} to="/">
                <img
                  src={isDwpPage ? logoDwpLight : logoLight}
                  className="logo-light img-fluid"
                  alt="Logo"
                />
                <img
                  src={isDwpPage ? logoDwpDark : logoDark}
                  className="logo-dark img-fluid"
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="col-actions">
              <ul className="navbar-nav">
                {account ? (
                  <div className="d-none d-md-flex mr-3">
                    <ModalDisconnectWallet
                      id={true}
                      show={modalDisconnect}
                      hide={() => setModalDisconnect(false)}
                    />
                    <li
                      className="nav-item wallet-address"
                      onClick={() => setModalDisconnect(true)}
                    >
                      {truncate(account, 13)}
                    </li>
                  </div>
                ) : null}
                <li className="nav-item curr-lang-options">
                  <select
                    className="nav-link"
                    value={Cookies.get(process.env.CURRENCY)}
                    onChange={(e) => {
                      Cookies.set(process.env.CURRENCY, e.target.value)
                      window.location.reload()
                    }}
                  >
                    <option value="IDR">IDR</option>
                    <option value="USD">USD</option>
                  </select>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    disabled={disabledSearch}
                    onClick={handleSearch}
                  >
                    <i className="air ai-search" />
                  </button>
                </li>

                <li className={`nav-item d-none d-${navExpand}-flex`}>
                  <Link
                    className="nav-link account-button"
                    to={!user_data ? login_link : ""}
                    onClick={
                      user_data && (() => setAccountDropdown(!accountDropdown))
                    }
                  >
                    <i className="air ai-user" />
                    {user_data ? (
                      <ul
                        className={`account-list pl-0 py-3 ${
                          accountDropdown ? "" : "hide"
                        }`}
                      >
                        <li className="account-item">
                          <Link to={my_order_link}>My Orders</Link>
                        </li>
                        <li className="account-item">
                          <Link to={my_address_link}>My Address</Link>
                        </li>
                        <li className="account-item">
                          <Link to={my_account_link}>Settings</Link>
                        </li>
                        <li
                          className="account-item text-danger"
                          onClick={handleLogout}
                        >
                          Log Out
                        </li>
                      </ul>
                    ) : null}
                  </Link>
                </li>
                <li className="nav-item">
                  <button className="nav-link" onClick={handleShoppingBag}>
                    <i className="air ai-shopping-bag" />
                    {my_carts?.items?.length > 0 && (
                      <span className="ml-2">{my_carts?.items?.length}</span>
                    )}
                  </button>
                </li>
                <div className={`nav-item nav-item-menu d-${navExpand}-none`}>
                  <button
                    className="nav-icon nav-toggler inactive absolute"
                    onClick={() => handleOpen()}
                  >
                    <span className="icon-bar top-bar" />
                    <span className="icon-bar middle-bar" />{" "}
                    <span className="icon-bar bottom-bar" />
                  </button>
                </div>
              </ul>
            </div>

            <SearchMenu
              handleSearch={handleSearch}
              handleSearchMobile={handleSearchMobile}
              navExpand={navExpand}
              variant="basic"
            />
          </div>
        </div>

        <BagMenu handleShoppingBag={handleShoppingBag} variant="slide-left" />

        <div className="first-menu menu-bg" onClick={handleShoppingBag} />

        {/* MEGA MENU ********************************************* */}
        <MegaMenu
          showMegaMenu={showMegaMenu}
          setShowMegaMenu={setShowMegaMenu}
        />
      </nav>
    </>
  )
}

//BACKUP
{
  /* <DesktopMenu
  handleSearch={handleSearch}
  navExpand={navExpand}
  handleShoppingBag={handleShoppingBag}
  shoppingBagMenu={shoppingBagMenu}
  disableMenu={disableMenu}
  handleSelectMenu={setShowMegaMenu}
  megamenu={showMegaMenu}
/> */
}

// const closeNavTop = () => {
//   // const navTop = document.querySelector(".navbar-top")
//   // navTop.classList.add("hide")
//   setShowNavTop(false)
//   setTimeout(() => {
//     dispatch({ type: SET_TOAST_HEIGHT, payload: false })
//   }, 200)
// }

// const handleAccountMenuMobile = () => {
//   disableAccountMenu()
//   if (mobileAccountMenu.initial === false) {
//     setMobileAccountMenu({ initial: null, clicked: true })
//   } else if (mobileAccountMenu.clicked === true) {
//     setMobileAccountMenu({ clicked: !mobileAccountMenu.clicked })
//   } else if (mobileAccountMenu.clicked === false) {
//     setMobileAccountMenu({ clicked: !mobileAccountMenu.clicked })
//   }
// }

// const handleMenuMobile = () => {
//   disableMenu()
//   if (mobileMenu.initial === false) {
//     setMobileMenu({ initial: null, clicked: true })
//   } else if (mobileMenu.clicked === true) {
//     setMobileMenu({ clicked: !mobileMenu.clicked })
//     setMobileShopMenu({ clicked: false })
//     setMobileAccountMenu({ clicked: false })
//   } else if (mobileMenu.clicked === false) {
//     setMobileMenu({ clicked: !mobileMenu.clicked })
//   }
// }

// const disableAccountMenu = () => {
//   setDisabledAccountMenu(!disabledAccountMenu)
//   setTimeout(() => {
//     setDisabledAccountMenu(false)
//   }, 1500) // set this with your mobile menu animation duration
// }

// const handleShopMenuMobile = () => {
//   disableShopMenu()
//   if (mobileShopMenu.initial === false) {
//     setMobileShopMenu({ initial: null, clicked: true })
//   } else if (mobileShopMenu.clicked === true) {
//     setMobileShopMenu({ clicked: !mobileShopMenu.clicked })
//   } else if (mobileShopMenu.clicked === false) {
//     setMobileShopMenu({ clicked: !mobileShopMenu.clicked })
//   }
// }

//navbar top close function
// const [showNavTop, setShowNavTop] = useState(true)

// Toggler disabled state & handler
// const [disabledAccountMenu, setDisabledAccountMenu] = useState(false)
